
import { defineComponent, computed, reactive, toRefs } from 'vue';
import { Field, Form } from 'vee-validate';
import { Actions } from '@/store/enums/StoreEnums';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2/dist/sweetalert2.js';

export default defineComponent({
  name: 'sign-in',
  components: {
    Field,
    Form,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      isLoading: false,
    });
    const router = useRouter();

    //Form submit function
    const onSubmitLogin = (values) => {
      state.isLoading = false;
      store
        .dispatch(Actions.LOGIN, values)
        .then(() => {
          state.isLoading = false;
          router.push({ name: 'Dashboard' });
        })
        .catch(() => {
          state.isLoading = false;
          const message = store.getters.getErrors;

          Swal.fire({
            text: message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Ok, got it!',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
        });
    };

    return {
      errors: computed(() => {
        return store.getters.getErrors;
      }),
      onSubmitLogin,
      ...toRefs(state),
    };
  },
});
